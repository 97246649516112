<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const appConfig = useAppConfig()

// cookies: preserve app state in cookies to be present both in SSR and CSR

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobileReal = computed(() => breakpoints.smallerOrEqual('lg')?.value)
const isMobile = useCookie('is-mobile', { default: () => isMobileReal.value })
watch(isMobileReal, value => isMobile.value = value, { immediate: true })
</script>

<template>
  <div class="h-screen flex items-center justify-center overlay">
    <!-- <ClientOnly>
      <ThemeBackgroundTetris class="w-full h-full -z-10" />
    </ClientOnly> -->
    <UDashboardLayout class="z-10">
      <UDashboardPanel grow>
        <UDashboardNavbar title="La Tinta" class="z-10 bg-background">
          <template #left>
            <span class="flex items-center gap-3">
              <img
                :src="appConfig.netzo.favicon"
                :alt="appConfig.netzo.title"
                class="h-10 w-auto"
              >
              <h1 class="text-gray-900 dark:text-white text-2xl font-bold truncate">
                {{ appConfig.netzo.title }}
              </h1>
            </span>
          </template>
          <template #right>
            <AppIssuesButtonSlideover />
          </template>
        </UDashboardNavbar>

        <slot />

        <UDivider />

        <footer class="px-4 py-4 flex items-center justify-between">
          <span class="text-sm text-gray-500 dark:text-gray-400">
            <strong>Netzo</strong> - tu aliado en tecnología
          </span>
          <NetzoLogo class="h-10 w-auto" />
          <a
            href="mailto:hello@netzo.io"
            class="hidden sm:flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400"
          >
            <UIcon name="i-mdi-email" class="h-5 w-5" />
            hello@netzo.io
          </a>
        </footer>
      </UDashboardPanel>
    </UDashboardLayout>
  </div>
</template>

<style scoped>
/* hide UDashboardNavbarToggle from toolbar */
:deep([aria-label="Open sidebar"]) {
  display: none;
}
</style>
